<template>

    <md-field-group>
     <div style="box-sizing: border-box;width:300px;height:36px;border: 2px solid #DDD;border-radius: 5px;margin-top: 27px;margin-left: 50%;transform: translateX(-50%);">

              <div style="box-sizing: border-box;float: left;width: 32px;height: 32px;background: rgb(233,236,239);background-position:8px 10px;border-right: 2px solid #DDD;">
         　　　　<img style="width: 16px;height: 16px;margin:8px;" src="../assets/password.png" />
         　　</div>
       <input v-model="password" style="box-sizing: border-box;margin-bottom: 1px;outline: none;width:264px;height:32px;padding-left: 10px;border:0;" type="danger" value="" placeholder="请输入密码"/>
     </div>

      <div style="box-sizing: border-box;width:300px;height:36px;border: 2px solid #DDD;border-radius: 5px;margin-top: 27px;margin-left: 50%;transform: translateX(-50%);">

        <div style="box-sizing: border-box;float: left;width: 32px;height: 32px;background: rgb(233,236,239);background-position:8px 10px;border-right: 2px solid #DDD;">

        　<img style="width: 16px;height: 16px;margin:8px;" src="../assets/password.png" />
       </div>
        　　<input v-model="rePassword" style="box-sizing: border-box;margin-bottom: 1px;outline: none;width:264px;height:32px;padding-left: 10px;border:0;" type="password" placeholder="请再次输入密码" />
      </div>

          <div  >
            <van-button style="width: 100%; margin-top: 10%"  type="danger"  @click="setPassword">确认修改</van-button>
          </div>
    </md-field-group>

</template>
<script>
import router from '../router'
import { userName } from '../utils/validate'
export default {

  data () {
    return {
      username: '',
      rePassword: '',
      password: '',
      openId: ''

    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {

    setPassword () {
      const self = this
      if (this.password != this.rePassword) {
        self.$toast('俩次密码不一致')
        return
      }
      const parmes = {
        username: this.username,
        password: this.password,
        openId: this.openId

      }
      this.$post('/wx/proxy/setPassword', parmes).then(resp => {
        if (resp.errno == 0) {
          self.$toast('修改成功')

          router.push({ name: 'login', params: { openId: this.openId } })
        }
      })
    },

    getUserInfo () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      console.log(userInfo)
      if (userInfo == null) {
        router.push({ name: 'login' })
      } else {
        this.username = userInfo.username
        this.openId = userInfo.openId
      }
    }

  }
}
</script>
<style lang="less" scoped>

</style>
