<template>

    <div>
        <img :src="identImage" style="width: 100%;margin-top: 10%;">

    </div>

</template>
<script>
import router from '../router'
export default {

  data () {
    return {

      identImage: '',

      openId: sessionStorage.getItem('openId') ? sessionStorage.getItem('openId') : ''
    }
  },
  mounted () {
    this.login()
  },
  methods: {

    login () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.identImage = userInfo.identUrl
    }

  }
}
</script>
<style lang="less" scoped>

</style>
