<template>
    <div class="login"  style="-webkit-transform: translateY(85%);">
      <div class="store_header">

      </div>

      <md-field-group>
       <div style="box-sizing: border-box;width:300px;height:36px;border: 2px solid #DDD;border-radius: 5px;margin-top: 27px;margin-left: 50%;transform: translateX(-50%);">

                <div style="box-sizing: border-box;float: left;width: 32px;height: 32px;background: rgb(233,236,239);background-position:8px 10px;border-right: 2px solid #DDD;">
           　　　　<img style="width: 16px;height: 16px;margin:8px;" src="../assets/login.png" />
           　　</div>
         <input v-model="username" style="box-sizing: border-box;margin-bottom: 1px;outline: none;width:264px;height:32px;padding-left: 10px;border:0;" type="text" value="" placeholder="请输入账号"/>
       </div>

        <div style="box-sizing: border-box;width:300px;height:36px;border: 2px solid #DDD;border-radius: 5px;margin-top: 27px;margin-left: 50%;transform: translateX(-50%);">

          <div style="box-sizing: border-box;float: left;width: 32px;height: 32px;background: rgb(233,236,239);background-position:8px 10px;border-right: 2px solid #DDD;">

          　<img style="width: 16px;height: 16px;margin:8px;" src="../assets/password.png" />
         </div>
          　　<input v-model="password" style="box-sizing: border-box;margin-bottom: 1px;outline: none;width:264px;height:32px;padding-left: 10px;border:0;" type="password" placeholder="请输入密码" />
        </div>

            <div  >
              <van-button style="width: 100%; margin-top: 10%"  type="danger"  @click="login">登录</van-button>
            </div>
      </md-field-group>

    </div>
  </template>
<script>
import router from '../router'
export default {

  data () {
    return {

      loading: false,
      username: '',
      password: '',
      code: '',
      openId: ''
    }
  },
  mounted () {
    this.getWxSeting()
  },
  methods: {

    login () {
      const parmes = {
        username: this.username,
        password: this.password,
        openId: this.openId
      }
      this.$post('/wx/proxy/login', parmes).then(resp => {
        if (resp.errno == 0) {
          console.log(resp.data)

          sessionStorage.setItem('userInfo', JSON.stringify(resp.data))
          router.push({ name: 'user', params: { openId: this.openId } })
        }
      })
    },

    // 获取微信配置
    getWxSeting () {
      this.$post('/wx/order/getWxConfig', '').then(resp => {
        if (resp) {
          this.getCode(resp.appId)
          this.getOpenId()
        }
      })
    },

    getCode (appId) {
      const url = window.location.search
      const start = window.location.search.indexOf('=')
      const end = window.location.search.indexOf('&')
      const code = url.substring(start + 1, end) // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href
      // const local = 'http://www.youling123.top'
      if (code == null || code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
      } else {
        this.code = code
        if (!sessionStorage.getItem('openId')) {
          this.getOpenId() // 把code传给后台获取用户信息
        }
      }
    },
    getOpenId () { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
      this.$get('/wx/home/getOpenId', { code: this.code }).then((resp) => {
        sessionStorage.setItem('openId', resp.openid)
        console.log(resp.openid)
        this.openId = resp.openid
        this.openIdObj = resp
        this.login()
      })
    }

  }
}
</script>
  <style lang="less" scoped>

  </style>
