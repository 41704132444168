<template>
    <div>
        <div class="detail-box">
          <div class="detail-top">
            <img src="../assets/a.png" alt="">
            <div class="detail-title">
              <span class="title">旅游攻略</span>
              <span class="time">共有攻略{{total}}个</span>
            </div>

          </div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="detail-bottom" v-for="(item,index) in list" :key="index">

                 <div class="audio-box" style="width:50%;">
                   <img :src="item.picUrl" style="width:200%;" alt="">
                 </div>
                 <div class="list">
                   <div>
                     <div class="title">{{item.detail}}</div>
                      [点击链接预约{{item.name}}景点门票]
                     <a :href="item.link"> {{item.link}} </a>
                   </div>

                 </div>

            </div>
            </van-list>

        </div>

    </div>
</template>
<script>
import { AudioPlayer } from '@liripeng/vue-audio-player'
import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
export default {
  components: {
    AudioPlayer
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,
      total: 0,
      obj: {},
      audioList: [],
      zfStart: '',
      phoneShow: false
    }
  },
  mounted () {

  },
  methods: {

    onLoad () {
      this.page++
      const parmes = {

        limit: 10,
        page: this.page

      }
      this.$get('/wx/sport/list', parmes).then(resp => {
        if (resp.errno == 0) {
          this.total = resp.data.total
          resp.data.list.forEach((e, index) => {
            if (this.page == 1) {
              if (index <= 1) {
                e.play = true
              } else {
                e.play = false
              }
            }

            const arr = []
            arr.push(e.audioUrl)
            e.audioArr = arr
            this.list.push(e)
          })
          console.log(this.finished)
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true
          }
        }
      })
    },

    pause (index) {
      this.$refs.AudioPlayer[index].pause()
      this.list.forEach((e, i) => {
        if (index == i) {
          e.isPlay = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
p{
    margin: 0;
}
.detail-box{
    height: auto;
    overflow: hidden;
    .detail-top{
        width: 95%;
        padding: .15rem 2.5%;
        background-color: #fff;
        height: 1.2rem;
        position: relative;
        margin-bottom: .1rem;
        img{
            width: 1.2rem;
            height: 1.2rem;
            float: left;
            border-radius: .1rem;
        }
        .detail-title{
            width: calc(100% - 1.4rem);
            padding-left: .2rem;
            height: 1.2rem;
            float: left;
            .title{
                font-weight: bold;
                display: block;
                height: .7rem;
                line-height: .8rem;
                font-size: .35rem;
            }
            .time{
                display: block;
                height: .5rem;
                font-size: .2rem;
                color: #919191;
            }
        }
        .phone{
            position: absolute;
            right: .2rem;
            top: .6rem;
        }
    }
    .detail-bottom{
        width: 95%;
        padding: .15rem 2.5%;
        background-color: #fff;
        border-bottom:solid 1px #f0f0f0;
        .list{
            min-height: 1.1rem;
            .list-left{
                width: 80%;
                float: left;
                .title{
                    height: .4rem;
                    line-height: .4rem;
                    font-size: .28rem;
                    span{
                        display: inline-block;
                        p{
                            display: inline-block;
                            margin: 0;
                        }
                    }
                }
                .video-box{
                    margin-top: .1rem;
                    height: .6rem;
                    line-height: .6rem;
                    padding-left: 5%;
                    .audio-box{
                        width: calc(100% - .5rem);
                        float: right;
                        height: .6rem;
                        audio{
                            width: 100%;
                            .phase-ready{
                                height: .6rem;
                            }
                        }
                    }
                }
            }
            img{
                width: 1.1rem;
                height: 1.1rem;
                float: right;
                border-radius: .08rem;
            }
        }
    }
    .money-box{
        position: fixed;
        bottom: 0;
        left: 0;
        height: .8rem;
        padding: .1rem 2.5%;
        width: 95%;
        z-index: 99;
        background-color: #fff;
        .money{
            width: 60%;
            height: .8rem;
            float: left;
            span{
                display: block;
            }
            .money01{
                height: .5rem;
                line-height: .5rem;
                font-weight: bold;
                font-size: .35rem;
            }
            .ting{
                height: .3rem;
                line-height: .3rem;
                font-size: .2rem;
                color: #999;
            }
        }
        .zf-botton{
            background-color: #33c5e1;
            border-radius: .2rem;
            color: #fff;
            float: right;
            padding: 0 .3rem;
            height: .7rem;
            line-height: .75rem;
            margin-top: .1rem;
            overflow: hidden;
        }
    }
}
</style>
