<template>
  <div class="user_information">

    <div class="user_header" :style="{backgroundImage: `url(${background_image})`}">
      <van-icon name="set" class="user_set"/>
      <div class="user_avatar">
        <img :src="avatar" alt="头像" width="55" height="55">
      </div>
      <div>{{nickName}}</div>
    </div>

    <van-cell-group>
<!--      <van-cell title="头像" class="cell_middle">-->
<!--        <van-uploader :afterRead="avatarAfterRead">-->
<!--          <div class="user_avatar_upload">-->
<!--            <img-->
<!--              :src="avatar + '?x-oss-process=image/resize,m_fill,h_50,w_50'"-->
<!--              alt="你的头像"-->
<!--              v-if="avatar"-->
<!--            >-->
<!--            <van-icon name="camera_full" v-else></van-icon>-->
<!--          </div>-->
<!--        </van-uploader>-->
<!--      </van-cell>-->

      <van-cell title="推广二维码" to="/erweima"   isLink/>
      <van-cell title="我的团队"   to="/myTeam" isLink/>
      <van-cell title="我的订单" to="/myOrder"  isLink/>
<!--      <van-cell title="订单统计" to="/orderStatistics"  isLink/>-->
      <van-cell title="我的提成" to="/myCommission"   isLink/>
      <van-cell title="提现记录" to="/cashOutRecord"   isLink></van-cell>
      <van-cell title="修改密码" to="/setPassword"  isLink></van-cell>
    </van-cell-group>

  </div>
</template>
<script>
import avatar_default from '@/assets/avatar_default.png'
import bg_default from '@/assets/user_head_bg.png'
import router from '../router'
export default {
  data () {
    return {

      nickName: '昵称',
      avatar: avatar_default,
      background_image: bg_default
    }
  },

  computed: {

  },

  created () {
    this.getUserInfo()
  },

  methods: {
    avatarAfterRead (file) {
      console.log(file)
    },
    onSexConfirm (value, index) {
      this.showSex = false
    },
    getUserInfo () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      console.log(userInfo)
      if (userInfo == null) {
        router.push({ name: 'login' })
      } else {
        this.nickName = userInfo.username
      }
    },
    loginOut () {

    }
  }

}
</script>
<style lang="less" scoped>
  .user_information {
    .user_avatar_upload {
      position: relative;
      width: 50px;
      height: 50px;
      border: 1px solid ;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
      }
    }
    .user_quit {
      margin-top: 20px;
      color: #1989fa;
    }
  }

  .user_header {
    background-repeat: no-repeat;
    background-size: cover;
    height: 130px;
    text-align: center;
    color: #fff;
    padding-top: 30px;
  }

  .user_set {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
  .user_avatar {
    margin-bottom: 10px;
    img {
      border: 0;
      border-radius: 50%;
    }
  }
</style>
