<template>
    <div>
        <div class="detail-box">
            <div class="detail-top">
                <img :src="obj.picUrl" alt="">
                <div class="detail-title">
                    <span class="title">{{obj.name}}</span>
                    <span class="time">时长{{obj.duration}}分钟 {{obj.conuntNum}}个讲解点</span>
                </div>
                <a><van-icon class="phone" name="phone" size=".45rem" color="#999" @click="phoneClick()" /></a>
            </div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="detail-bottom" v-for="(item,index) in list" :key="index">
                <div class="list">
                    <div class="list-left">
                        <div class="title">{{index+1}}.{{item.name}}</div>
                        <div class="video-box" v-show="!item.play"  @click="zfShow = true" >
                            <van-icon name="stop-circle-o" size=".38rem" color="#1989fa" />
                            <span style="margin-left: 20%;font-size: 12px;color: #808080;"> 请购买后收听</span>

                        </div>
                        <div class="video-box" v-show="item.play">
                            <van-icon v-show="!item.isPlay" name="play-circle-o" size=".38rem" @click="play(index,item)" color="#1989fa" />
                            <van-icon v-show="item.isPlay" name="pause-circle-o" size=".38rem" @click="pause(index)" color="#1989fa" />
                            <div class="audio-box">
                                <AudioPlayer ref="AudioPlayer" :audio-list="item.audioArr"  @ended="onAudioEnded" :before-play="onBeforePlay" :show-prev-button="false" :show-next-button="false" :isLoop="false" :show-play-button="false" />
                            </div>
                        </div>
                    </div>
                    <img :src="item.picUrl" alt="">
                </div>
            </div>
            </van-list>
            <div class="money-box" v-if="!zfStart">
                <div class="money">
                    <span class="money01">￥{{obj.pointPrice}}</span>
                    <span class="ting">永久收听</span>
                </div>
                <span class="zf-botton" @click="zfShow = true">去支付</span>
            </div>
        </div>
        <van-popup v-model="phoneShow" :style="{ width: '80%' }">
            <van-cell title="客服电话" icon="manager">
                <a style="width:100%;height:100%;display:block;" :href="'tel:'+obj.phone"></a>
            </van-cell>
            <van-cell title="景区救援电话" icon="phone">
                <a style="width:100%;height:100%;display:block;" :href="'tel:'+obj.brandPhone"></a>
            </van-cell>
        </van-popup>
        <!-- <span class="gold-coin-zhi" @click="show = true" v-if="!hongbao">
            <img src="../assets/h.png" alt="">
        </span> -->
        <van-dialog v-model="show" :title="`解锁${belong}全部景点`" show-cancel-button @confirm="confirm">
            <div class="dialog" style="font-size:.5rem;color:red; padding-top:.2rem;padding-bottom:.2rem;text-align:center;">
                {{typeObj.belongPrice}}
            </div>
        </van-dialog>
        <van-popup v-model="zfShow" closeable position="bottom" style="height:4.2rem;">
            <div class="zf-box">
                <span class="jiesuo">解锁</span>
                <span class="jiesuo" style="font-size:.26rem;line-height:.4rem;color: red">一次解锁，永久使用,不限次数</span>
                <van-radio-group v-model="statue">
                    <van-row>
                        <van-col span="4">
                            <span class=""><img :src="obj.picUrl" alt=""></span>
                        </van-col>
                        <van-col span="4" style="line-height:.4rem;">￥{{typeObj.belongPrice}}</van-col>
                        <van-col span="12" style="line-height:.4rem; ">解锁{{belong}}全部景点</van-col>
                        <van-col span="4" style="line-height:.4rem; text-align:center;"><van-radio name="1" style="display:inline-block;" checked-color="#33c5e1"></van-radio></van-col>
                    </van-row>
                    <van-row style="margin-top:.1rem;">
                        <van-col span="4">
                            <img :src="obj.picUrl" alt="">
                        </van-col>
                        <van-col span="4" style="line-height:.4rem;">￥{{obj.pointPrice}}</van-col>
                        <van-col span="12" style="line-height:.4rem;">{{obj.name}}全部{{obj.conuntNum}}个讲解点</van-col>
                        <van-col span="4" style="line-height:.4rem; text-align:center;"><van-radio name="0" style="display:inline-block;" checked-color="#33c5e1"></van-radio></van-col>
                    </van-row>
                    <van-row style="margin-top:.1rem;">
                        <van-col span="24">
                            <span class="zf-botton" @click="zfPay(),loading = true" v-if="!loading">确认支付</span>
                            <van-loading type="spinner" v-else color="#33c5e1" style="float:right;margin-right:.5rem;" />
                        </van-col>
                    </van-row>
                </van-radio-group>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { AudioPlayer } from '@liripeng/vue-audio-player'
import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
export default {
  components: {
    AudioPlayer
  },
  data () {
    return {
      currentIndex:0,
      list: [],
      playIndexList:[],
      loading: false,
      finished: false,
      page: 0,
      total: 0,
      obj: {},
      audioList: [],
      zfStart: '',
      phoneShow: false,
      loading: false,
      show: false,
      hongbao: true,
      typeObj: {},
      belong: '',
      zfShow: false,
      statue: '0'
    }
  },
  mounted () {  
    this.detail()
   
  },
  methods: {
 

    phoneClick () {
      this.phoneShow = true
    },
    onBeforePlay (next) {
      console.log('这里可以做一些事情...')
      next() // 开始播放
    },
    detail () {
      // var openIDtest='oIrf9waIc8_1hfi_Z3TuMhaiSGE4'
        this.$get('/wx/brand/detail', { brandId: this.$route.query.id, openId: this.$route.query.openId }).then(resp => {
      // this.$get('/wx/brand/detail', { brandId: this.$route.query.id, openId: openIDtest }).then(resp => {
        if (resp.errno == 0) {
          this.obj = resp.data
          this.belong = resp.data.belong
          this.getPayType()
        }
      })
    },
    zfPay () {
 
      const self = this
      const parmes = {
        brandId: self.$route.query.id,
        openId: self.$route.query.openId,
        statue: self.statue,
        belong: self.belong
      }
      this.$post('/wx/order/h5pay', parmes).then(resp => {
        if (resp.errno == 0) {
          resp.data.package = resp.data.packageValue
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', resp.data,
            (res) => {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                self.finished = false
                self.loading = false
                self.list = []
                self.$toast('支付成功')
                self.page = 0
                self.onLoad()
                self.zfShow = false
              } else {
                self.$toast('支付失败')
                self.loading = false
                self.zfShow = false
              }
            }
          )
        }
      })
    },
    onLoad () {
      this.page++
      // var openIDtest='oIrf9waIc8_1hfi_Z3TuMhaiSGE4'

      const parmes = {
        brandId: this.$route.query.id,
        limit: 10,
        page: this.page,
        openId: this.$route.query.openId
      }
      //测试用
      // const parmes = {
      //   brandId: this.$route.query.id,
      //   limit: 1000,
      //   page: this.page,
      //   openId: openIDtest
      // }
      this.$get('/wx/content/list', parmes).then(resp => {
        if (resp.errno == 0) {
          this.total = resp.data.total
          this.zfStart = resp.data.flag
          resp.data.list.forEach((e, index) => {
            if (!resp.data.flag) {
              if (this.page == 1) {
                if (index <= 1) {
                  e.play = true
                } else {
                  e.play = false
                }
              }
            } else {
              e.play = true
            }
            e.isPlay = false
            const arr = []
            arr.push(e.audioUrl)
            e.audioArr = arr
            this.list.push(e)
          })
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true
          }
        }
      })
    },
    play (index,item) {
      var  self = this
      //语音联播功能实现 1.没有购买播放如果点击第二条不联播 如果点击第一条联播 2.购买之后 按传入进来index一直播放到结束


      self.playIndexList = [];
      var playableList =[]
      //获得所有可播放下标
      this.list.forEach((e, i) => {
          if (e.play==true){
            playableList.push(i);
          }
      })

      for (var i=index;i<self.list.length;i++){
        for (var k=0;k< playableList.length;k++) {
          var ind =  playableList[k];
          if (ind==i){
            self.playIndexList.push(i);
          }
        }
      }

      self.currentIndex=0;
      self.audioPlay( self.playIndexList[self.currentIndex])
    },
    audioPlay(index){
      let self = this
      this.list.forEach((e,i)=>{
        if(index == i){
          e.isPlay = true
          self.$refs.AudioPlayer[i].play()
        }else{
          e.isPlay = false
          self.$refs.AudioPlayer[i].pause()
        }
      })
    },
    onAudioEnded() {
      this.nextAudio();
    },
    nextAudio() {
      this.currentIndex = (this.currentIndex + 1) % this.playIndexList.length;

      this.audioPlay(this.playIndexList[this.currentIndex]); // 播放下一个音频文件
    },
    pause (index) {
      this.$refs.AudioPlayer[index].pause()
      this.list.forEach((e, i) => {
        if (index == i) {
          e.isPlay = false
        }
      })
    },
    // 获取是否已经支付完毕
    getPayType (belong) {
      this.$get('/wx/content/getAllBelongData', { openId: this.$route.query.openId, belong: this.belong }).then(resp => {
        if (resp.errno == 0) {
          this.hongbao = resp.data[0].payFlag
          this.typeObj = resp.data[0]
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.zf-box{
    padding: 10px;
    .jiesuo{
        width: 100%;
        display: block;
        height: .6rem;
        line-height: .6rem;
        font-size: .36rem;
    }
    img{
        width: .8rem;
        height: .8rem;
        float: left;
        border-radius: .1rem;
    }
}
p{
    margin: 0;
}
.detail-box{
    height: auto;
    overflow: hidden;
    .detail-top{
        width: 95%;
        padding: .15rem 2.5%;
        background-color: #fff;
        height: 1.2rem;
        position: relative;
        margin-bottom: .1rem;
        img{
            width: 1.2rem;
            height: 1.2rem;
            float: left;
            border-radius: .1rem;
        }
        .detail-title{
            width: calc(100% - 1.4rem);
            padding-left: .2rem;
            height: 1.2rem;
            float: left;
            .title{
                font-weight: bold;
                display: block;
                height: .7rem;
                line-height: .8rem;
                font-size: .35rem;
            }
            .time{
                display: block;
                height: .5rem;
                font-size: .2rem;
                color: #919191;
            }
        }
        .phone{
            position: absolute;
            right: .2rem;
            top: .6rem;
        }
    }
    .detail-bottom{
        width: 95%;
        padding: .15rem 2.5%;
        background-color: #fff;
        border-bottom:solid 1px #f0f0f0;
        .list{
            min-height: 1.1rem;
            .list-left{
                width: 80%;
                float: left;
                .title{
                    height: .4rem;
                    line-height: .4rem;
                    font-size: .28rem;
                    span{
                        display: inline-block;
                        p{
                            display: inline-block;
                            margin: 0;
                        }
                    }
                }
                .video-box{
                    margin-top: .1rem;
                    height: .6rem;
                    line-height: .6rem;
                    padding-left: 5%;
                    .audio-box{
                        width: calc(100% - .5rem);
                        float: right;
                        height: .6rem;
                        audio{
                            width: 100%;
                            .phase-ready{
                                height: .6rem;
                            }
                        }
                    }
                }
            }
            img{
                width: 1.1rem;
                height: 1.1rem;
                float: right;
                border-radius: .08rem;
            }
        }
    }
    .money-box{
        position: fixed;
        bottom: 0;
        left: 0;
        height: .8rem;
        padding: .1rem 2.5%;
        width: 95%;
        z-index: 99;
        background-color: #fff;
        .money{
            width: 60%;
            height: .8rem;
            float: left;
            span{
                display: block;
            }
            .money01{
                height: .5rem;
                line-height: .5rem;
                font-weight: bold;
                font-size: .35rem;
            }
            .ting{
                height: .3rem;
                line-height: .3rem;
                font-size: .2rem;
                color: #999;
            }
        }
    }
}
.zf-botton{
    background-color: #33c5e1;
    border-radius: .2rem;
    color: #fff;
    float: right;
    padding: 0 .3rem;
    height: .7rem;
    line-height: .75rem;
    margin-top: .1rem;
    overflow: hidden;
}
.gold-coin-zhi{
    position: fixed;
    right: .5rem;
    bottom: 3rem;
    z-index: 99;
    width: .52rem;
    height: .6rem;
    display: inline-block;
    z-index: 99;
    img{
        width: 100%;
        height: 100%;
    }
}
</style>
