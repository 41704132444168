<template>
 <div>
   <div class="user_information">

     <div class="user_header" :style="{background: '#EBEBEB',height: '40%',padding: '20px',borderRadius: '30px',border: '2px'}">

       <div style="margin-left: -60%;">
         <img style="width: 16px;height: 16px; margin: 8px 5px -4px -10%;" src="../assets/login.png" />
         <span>
         {{nickName}}
      </span> </div>
       <van-icon name="set" class="user_set" />
       <div class="user_avatar">
         <div>
           <span style="font-size: 35px;">{{amountNow+amountSum}}</span>
           <br />
           <span  style="font-size: 12px;">累计收益(元)</span>
         </div>

         <div style="margin-left: -50%;margin-top: 10%;">
           <span style="font-size: 28px;">{{amountNow}}</span>
           <br />
           <span style="font-size: 12px;">剩余收益(元)</span>
         </div>

         <div style="margin-left: 50%; margin-top: -16%;">
           <span style="font-size: 28px;">{{amountSum}}</span>
           <br />
           <span  style="font-size: 12px;">已提现收益(元)</span>
         </div>

         <div style="margin-left: -8%;">

           <span class="zf-botton" style="font-size: 20px" @click="this.getAmountMony" >收益提现</span>
           <br>
           <span style="font-size: 12px;color: red"> 剩余收益必须大于2元才能提现</span>
           <br>
           <span style="font-size: 12px;color: red"> 最高单笔不超过500元，不限提现次数</span>
         </div>
       </div>

     </div>

     <van-cell-group style="background: cadetblue;margin-top: 2%">
       <van-cell title="已提现记录" to="/cashOutRecord"   isLink></van-cell>
       <van-cell title="已提现用户订单" to="/cashOutOrder"   isLink></van-cell>
     </van-cell-group>

   </div>

   <div class="detail-box">
     <van-list v-model="loading" :finished="finished" finished-text="" title="未提现记录" @load="onLoad">
       <div class="detail-bottom" v-for="(item,index) in list" :key="index">

         <div class="detail-box">
           <div>
             <div>订单编号: {{item.orderSn}}</div>
             <span>
                       订单时间：{{item.addTime}}
                     </span>
             <br>
             <span style="color: green;">
                        景点区域： {{item.payId}}
                       </span>
             <br>
             <span style="color: green;">
                        推广人： {{item.proxyId}}
                        </span>
             <br>
             <span style="color: red;">
                       订单金额：{{item.orderPrice}}元
                     </span>
           </div>
           <div style="margin-top: -20%;">
             <img :src="item.userId" alt="" style="width: 2.3rem; margin-left: 68%;">
           </div>
         </div>

       </div>
       <hr>
     </van-list>
   </div>
 </div>

</template>
<script>
import avatar_default from '@/assets/avatar_default.png'
import bg_default from '@/assets/user_head_bg.png'
import router from '../router'
export default {
  data () {
    return {
      amountNow: 0.00,
      amountSum: 0.00,
      nickName: '昵称',
      username: '',
      avatar: avatar_default,
      background_image: bg_default,
      list: [],
      loading: false,
      finished: false,
      page: 0,
      total: 0,
      obj: {},
      openId: ''
    }
  },

  computed: {

  },

  created () {
    this.getUserInfo()
    this.getAmountByOpenId()
  },

  methods: {

    getUserInfo () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      console.log(userInfo)
      if (userInfo == null) {
        router.push({ name: 'login' })
      } else {
        this.nickName = userInfo.username
        this.username = userInfo.username
      }
      this.openId = userInfo.openId
      this.nickName = userInfo.username
    },
    getAmountByOpenId () {
      const parmes = {
        openId: this.openId
      }
      this.$get('/wx/proxy/getAmountByOpenId', parmes).then(resp => {
        if (resp.errno == 0) {
          console.log(resp.data)
          this.amountNow = resp.data.amountNow
          this.amountSum = resp.data.amountSum
        }
      })
    },

    getAmountMony () {
      const self = this
      const parmes = {
        openId: this.openId,
        username: this.username
      }
      this.$post('/wx/proxy/tixian', parmes).then(resp => {
        if (resp.errno == 0) {
          self.$toast('提现成功')
          this.getAmountByOpenId()
          self.onLoad()
        } else {
          self.$toast(resp.errmsg)
          self.onLoad()
        }
      })
    },

    onLoad () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.username = userInfo.username

      this.page++
      const parmes = {
        username: this.username,
        limit: 10,
        statue: 0,
        page: this.page

      }
      this.$get('/wx/proxy/orderByProxyUsernameAndStatue', parmes).then(resp => {
        if (resp.errno == 0) {
          this.total = resp.data.total
          resp.data.list.forEach((e, index) => {
            this.list.push(e)
          })
          console.log(this.list)
          console.log(this.finished)
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true
            console.log(this.finished)
          }
        }
      })
    }
  }

}
</script>
<style lang="less" scoped>
  .user_information {
    .user_avatar_upload {
      position: relative;
      width: 50px;
      height: 50px;
      border: 1px solid ;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
      }
    }
    .user_quit {
      margin-top: 20px;
      color: #1989fa;
    }
  }
  .zf-botton{
    background-color: #33c5e1;
    border-radius: 1.2rem;
    width: 10rem;
    color: #fff;
    float: none;
    padding: 0 .3rem;
    height: .7rem;
    line-height: .75rem;
    margin-top: .1rem;
    overflow: hidden;
  }
  .user_header {
    background-repeat: no-repeat;
    background-size: cover;
    height: 130px;
    text-align: center;
    color: rgb(0, 0, 0);
    padding-top: 30px;
  }

  .user_set {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
  .user_avatar {
    margin-bottom: 10px;
    img {
      border: 0;
      border-radius: 50%;
    }
  }
  .detail-box{
    .detail-bottom{
      height: 10%;
      padding: .15rem 2.5%;
      background-color: #fff;
      border-bottom:solid 1px #f0f0f0;

    }

  }

</style>
