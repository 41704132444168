import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import detail from '../views/detail.vue'

import spot from '../views/spot.vue'
import startegy from '../views/startegy.vue'
import login from '../views/login.vue'
import user from '../views/user.vue'
import cashOutRecord from '../views/cashOutRecord.vue'
import erweima from '../views/erweima.vue'
import myCommission from '../views/myCommission.vue'
import myOrder from '../views/myOrder.vue'
import myTeam from '../views/myTeam.vue'
import setPassword from '../views/setPassword.vue'

import cashOutOrder from '../views/cashOutOrder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '游聆'
    },
    component: index
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '游聆|为游客省钱的语音导游'
    },
    component: detail
  },
  {
    path: '/spot',
    name: 'spot',
    meta: {
      title: '门票预约'
    },
    component: spot
  },
  {
    path: '/startegy',
    name: 'startegy',
    meta: {
      title: '旅游攻略'
    },
    component: startegy
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '游聆登陆'
    },
    component: login
  },

  {
    path: '/user',
    name: 'user',
    meta: {
      title: '个人中心'
    },
    component: user
  },

  {
    path: '/erweima',
    name: 'erweima',
    meta: {
      title: '推广二维码'
    },
    component: erweima
  },

  {
    path: '/myTeam',
    name: 'myTeam',
    meta: {
      title: '我的团队'
    },
    component: myTeam
  },

  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      title: '我的订单'
    },
    component: myOrder
  },

  {
    path: '/cashOutRecord',
    name: 'cashOutRecord',
    meta: {
      title: '提现记录'
    },
    component: cashOutRecord
  },

  {
    path: '/myCommission',
    name: 'myCommission',
    meta: {
      title: '我的提成'
    },
    component: myCommission
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    meta: {
      title: '修改密码'
    },
    component: setPassword
  },

  {
    path: '/cashOutOrder',
    name: 'cashOutOrder',
    meta: {
      title: '已提现订单'
    },
    component: cashOutOrder
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
