<template>
    <div>
        <div class="detail-box">
          <div class="detail-top">
            <img src="../assets/dingdan.png" alt="">
            <div class="detail-title">
              <span class="title">订单管理</span>
              <span class="time">共有订单{{total}}个</span>
            </div>

          </div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="detail-bottom" v-for="(item,index) in list" :key="index">

                 <div class="list">
                   <div  class="list-left">
                     <div class="title" >订单编号: {{item.orderSn}}</div>
                     <span>
                       订单时间：{{item.addTime}}
                     </span>
                     <br>
                       <span style="color: green;">
                        景点区域： {{item.payId}}
                       </span>
                     <br>
                     <span style="color: green;">
                        推广人： {{item.proxyId}}
                       </span>
                     <br>
                     <span style="color: red;">
                       订单金额：{{item.orderPrice}}
                     </span>

                   </div>
                   <img :src="item.userId" alt="">

                 </div>

            </div>
            </van-list>

        </div>

    </div>
</template>
<script>
import { AudioPlayer } from '@liripeng/vue-audio-player'
import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
export default {
  components: {
    AudioPlayer
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,
      total: 0,
      obj: {},
      audioList: [],
      zfStart: '',
      phoneShow: false,
      username: ''
    }
  },
  mounted () {

  },
  methods: {

    onLoad () {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.username = userInfo.username

      this.page++
      const parmes = {
        username: this.username,
        limit: 10,
        page: this.page

      }
      this.$get('/wx/proxy/orderByProxyUsername', parmes).then(resp => {
        if (resp.errno == 0) {
          this.total = resp.data.total
          resp.data.list.forEach((e, index) => {
            const arr = []
            arr.push(e.orderSn)
            e.audioArr = arr
            this.list.push(e)
          })
          console.log(this.finished)
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true
          }
        }
      })
    }

  }
}
</script>
<style lang="less" scoped>
  .zf-box{
    padding: 10px;
    .jiesuo{
      width: 100%;
      display: block;
      height: .6rem;
      line-height: .6rem;
      font-size: .36rem;
    }
    img{
      width: .8rem;
      height: .8rem;
      float: left;
      border-radius: .1rem;
    }
  }
  p{
    margin: 0;
  }
  .detail-box{
    height: auto;
    overflow: hidden;
    .detail-top{
      width: 95%;
      padding: .15rem 2.5%;
      background-color: #fff;
      height: 1.2rem;
      position: relative;
      margin-bottom: .1rem;
      img{
        width: 1.2rem;
        height: 1.2rem;
        float: left;
        border-radius: .1rem;
      }
      .detail-title{
        width: calc(100% - 1.4rem);
        padding-left: .2rem;
        height: 1.2rem;
        float: left;
        .title{
          font-weight: bold;
          display: block;
          height: .7rem;
          line-height: .8rem;
          font-size: .35rem;
        }
        .time{
          display: block;
          height: .5rem;
          font-size: .2rem;
          color: #919191;
        }
      }
      .phone{
        position: absolute;
        right: .2rem;
        top: .6rem;
      }
    }
    .detail-bottom{
      width: 95%;
      padding: .15rem 2.5%;
      background-color: #fff;
      border-bottom:solid 1px #f0f0f0;
      .list{
        min-height: 2.0rem;
        .list-left{
          width: 80%;
          float: left;
          .title{
            height: .4rem;
            line-height: .4rem;
            font-size: .28rem;
            span{
              display: inline-block;
              p{
                display: inline-block;
                margin: 0;
              }
            }
          }
          .video-box{
            margin-top: .1rem;
            height: .6rem;
            line-height: .6rem;
            padding-left: 5%;
            .audio-box{
              width: calc(100% - .5rem);
              float: right;
              height: .6rem;
              audio{
                width: 100%;
                .phase-ready{
                  height: .6rem;
                }
              }
            }
          }
        }
        img{
          width: 1.1rem;
          height: 1.1rem;
          float: right;
          border-radius: .08rem;
        }
      }
    }
    .money-box{
      position: fixed;
      bottom: 0;
      left: 0;
      height: .8rem;
      padding: .1rem 2.5%;
      width: 95%;
      z-index: 99;
      background-color: #fff;
      .money{
        width: 60%;
        height: .8rem;
        float: left;
        span{
          display: block;
        }
        .money01{
          height: .5rem;
          line-height: .5rem;
          font-weight: bold;
          font-size: .35rem;
        }
        .ting{
          height: .3rem;
          line-height: .3rem;
          font-size: .2rem;
          color: #999;
        }
      }
    }
  }
  .zf-botton{
    background-color: #33c5e1;
    border-radius: .2rem;
    color: #fff;
    float: right;
    padding: 0 .3rem;
    height: .7rem;
    line-height: .75rem;
    margin-top: .1rem;
    overflow: hidden;
  }
  .gold-coin-zhi{
    position: fixed;
    right: .5rem;
    bottom: 3rem;
    z-index: 99;
    width: .52rem;
    height: .6rem;
    display: inline-block;
    z-index: 99;
    img{
      width: 100%;
      height: 100%;
    }
  }
</style>
