<template>
    <div>
        <div class="index-box">
     <!--    <van-search v-model="keyWord" placeholder="请输入搜索关键词" />
            <van-swipe :autoplay="3000" class="swipe-box" :show-indicators = "false">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <router-link tag="img" :to="{name:'detail', query:{ id: image.id,openId:openIdObj.openid }}" :src="image.url" />
                </van-swipe-item>
            </van-swipe>   --> 
            <div class="all-name">
                全部讲解
            </div>
            <ul class="content-box">
              <li class="li-box" style="width: 100%">
                <i class="el-icon-map-location icon-style"  ></i>
                <p style="display: inline-block;" >山西</p>
              </li>
              <router-link tag="li" :to="{name:'detail', query:{ id: item.id,openId:openId }}" v-for="(item,index) in sxList" :key="index" class="li-box"  :style="{marginLeft:[index%2 != 0 ? '3%' : '2%']}">
                    <img :src="item.picUrl" alt="">
                    <p>{{item.name}}</p>
                </router-link>
            </ul>
          <ul class="content-box">
            <li class="li-box" style="width: 100%">
              <i class="el-icon-map-location icon-style" ></i>
              <p style="display: inline-block;" >西安</p>
            </li>
            <router-link tag="li" :to="{name:'detail', query:{ id: item.id,openId:openId }}" v-for="(item,index) in xaList" :key="index" class="li-box"  :style="{marginLeft:[index%2 != 0 ? '3%' : '2%']}">
                    <img :src="item.picUrl" alt="">
                    <p>{{item.name}}</p>
                </router-link>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      keyWord: '',
      images: [],
      list: [],
      xaList: [],
      sxList: [],
      openIdObj: {},
      code: '',
      openId: sessionStorage.getItem('openId') ? sessionStorage.getItem('openId') : ''
    }
  },
  mounted () {
    this.indexMsg()
    this.getWxSeting()
  },
  methods: {
    // 首页信息
    indexMsg () {
      var _this = this;
      this.$get('/wx/home/index').then(resp => {
        if (resp.errno == 0) {
          _this.list = resp.data.brandList
          console.log(".....")

          for(var obj of _this.list){
            var address = obj.belong
            if (address=="西安"){
              _this.xaList.push(obj)
              console.log("西安",JSON.toString(obj))
            }else if(address=="山西"){
              _this.sxList.push(obj)
              console.log("山西",JSON.toString(obj))

            }
          }
          this.images = resp.data.banner
        }
      })
    },
    // 获取微信配置
    getWxSeting () {
      this.$post('/wx/order/getWxConfig', '').then(resp => {
        if (resp) {
          this.getCode(resp.appId)
        }
      })
    },
    getCode (appId) {
      const url = window.location.search
      console.log("code URL"+url)
      const start = window.location.search.indexOf('=')
      const end = window.location.search.indexOf('&')
      const code = url.substring(start + 1, end) // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href
      // const local = 'http://www.youling123.top'
      if (code == null || code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
      } else {
        this.code = code
        if (!sessionStorage.getItem('openId')) {
          this.getOpenId() // 把code传给后台获取用户信息
        }
      }
    },
    getOpenId () { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
      this.$get('/wx/home/getOpenId', { code: this.code }).then((resp) => {
        sessionStorage.setItem('openId', resp.openid)
        this.openId = resp.openid
        this.openIdObj = resp
      })
    }
  }
}
</script>
<style lang="less" scoped>


.icon-style{
  margin-top: 5px;
  margin-left: 10px;
  display: inline-block;
  margin-right: 10px;
}
.index-box{
    width: 95%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    .van-search{
        padding:0;
        margin: .2rem 0;
        .van-search__content{
            background-color: #fff;
        }
    }
    .swipe-box{
        width: 100%;
        height: 2.5rem;
        img{
            width: 100%;
            height: 100%;
            border-radius: .1rem;
        }
    }
    .all-name{
        font-size: .32rem;
        font-weight: bold;
        height: .8rem;
        line-height: .8rem;
    }
    .content-box{
        background-color: #fff;
        border-top:solid 1px #ccc;
        width: 98%;
        padding: 0 1% 3% 1%;
        display: table;
        .li-box{
            width: 46.5%;
            display: inline-block;
            margin-left: 2%;
            margin-top: 3%;
            img{
                width: 100%;
                height: 2rem;
                border-radius: .1rem;
            }
            p{
                height: .5rem;
                line-height: .5rem;
                margin: 0;
            }
        }
    }
}

.dialog{
    padding-left: .6rem;
    padding-right: .6rem;
    text-align: center;
}
</style>
